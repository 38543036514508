.wrapper{
    position: fixed;
    left: 280px;
    top: 0;
    right: 0;
    height: 100vh;

    .back{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(10px);
    }

    .modal{
        position: absolute;
        top: 0%;
        right: 0%;
        left: 0;
        background: #2F3033;
        border-radius: 10px;
        width: 100%;
        box-shadow: 0px 0px 10px 5px #222222;
        height: 100vh;
        

        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px #383737 solid;
            padding: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background: #2F3033;
            z-index: 1;
    
            .title{
                font-size: 18px;
                font-weight: 500;
            }
    
            .close_wrapper{
                width: 20px;
                height: 20px;
                cursor: pointer;
    
                svg{
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .content{
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 20px;
            position: relative;
            max-height: 100%;
            padding-top: 80px;
            padding-bottom: 40px;
            overflow-y: auto;
            scrollbar-width: thin;

            .error{
                color: red;
            }
        }

        .footer{
            position: absolute;
            bottom: 0;
            background: #2F3033;
            z-index: 1;
            width: 100%;
            padding: 10px;
        }
    }
}