.dropdown {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}


.translateIcon {
    width: 25px;
    height: 25px;
    border-radius: 50%;

    &:hover {
        cursor: pointer;
    }
}

.content,
.content_row {
    display: flex;
    padding: 15px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--Main-pallet-Grayscale-80, #232428);
    height: 100%;



    &_head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;



        span {
            color: var(--Main-pallet-Accent-100, #EC7C40);
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
            /* 23.4px */
            letter-spacing: 0.18px;
        }
    }


    input{
        outline: none;
    }
}


.languageButtons {
    display: flex;
    align-items: center;
    gap: 5px;
}

.languageButton {
    border-radius: 20px;
    border: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
    background: unset;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset;
    display: flex;
    padding: 5px 10px;
    align-items: center;
    gap: 4px;
    transition: .3s;

    .check_icon {
        width: 15px;
        height: 15px;
    }

    &.selected {
        background: var(--Main-pallet-Accent-90, #EC722E);
    }

    &:hover {
        cursor: pointer;
    }
}