@import "../../../../index.scss";

.pages_modal {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.form {
  @include form;
}

.pages_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.group {
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Main-pallet-Grayscale-80, #232428);
}

.error_text {
  font-size: 13px;
  color: red;
}

.form_row {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .button_add {
    margin-top: 10px;
    width: max-content;
  }

  .wrap_buttons_add {
    margin-top: 10px;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    width: max-content;
  }

  label {
    color: var(--Main-pallet-Grayscale-50, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }

  select {
    option {
      display: flex;
      width: 143px;
      padding: 8px 16px 9px 16px;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      border-radius: 10px;
      background: var(--Main-pallet-Grayscale-90, #1E1E21);
    }
  }
}

.wrap_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 16px;
  margin-left: auto;
  padding-top: 20px;

  button {
    width: auto;
  }
}