.storage_wrapper {
  position: relative;
  width: 70%;
  height: 50%;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}

.back {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(12px);
  z-index: 9;
}
