.add_user {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.form {
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  border-radius: 15px;
  background: var(--Main-pallet-Grayscale-90, #1E1E21);

  h1 {
    color: var(--Main-pallet-Accent-100, #EC7C40);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 41.6px */
    letter-spacing: 0.32px;
  }


  .auth_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    &_border {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      align-self: stretch;
      border-radius: 8px;
      padding: 20px;
      border: 1px solid var(--Main-pallet-Grayscale-60, #3D3D3D);
    }

    .form_row {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
    }

    &_field {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

.wrap_buttons {
  display: flex;
  width: 449px;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}

.error_text {
  font-size: 13px;
  color: red;
}

.access {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  h2 {
    color: #EC722E;
    text-align: left;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 31.2px */
    letter-spacing: 0.24px;
  }

  &_subtitle {
    color: var(--Main-pallet-Grayscale-20, #E8E9E9);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.16px;
  }

  &_point {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    .checkbox_bottom {
       display: flex;
       gap: 30px;
     }
  }
}