.modal_wrapper{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

    .filter{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        backdrop-filter: blur(5px);
    }

    .modal{
        background: var(--Main-pallet-Grayscale-90, #1E1E21);
        margin-bottom: 20px;
        border-radius: 20px;
        animation: fadeIn 0.5s ease-in-out;
        display: flex;
        width: 600px;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 50px;
        flex-shrink: 0;
        box-sizing: border-box;
        z-index: 20;

        .footer{
            display: flex;
            gap: 15px;
            width: 100%;

            button{
                width: 50%;
            }
        }
    }
}