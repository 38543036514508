.languages {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  td {
    &.icons {
      display: flex;
      gap: 20px;
      justify-content: flex-end;
    }

    .tools_icon {
      cursor: pointer;
      float: right;

      svg {
        pointer-events: none;
        height: 24px;
        width: 24px;
        transition: .3s;

        &:hover {
          path {
            transition: .3s;
            fill: #EC722E !important;
          }
        }
      }
    }
  }

  .active {
    display: flex;
    width: 100px;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--Status-Green, #18CB4B);
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }

  .notActive {
    display: flex;
    width: 100px;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--Status-Gray, #707278);
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
}