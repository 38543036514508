.loading{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;

    svg{
        animation: rotation 1s infinite linear;
        width: 30px;
        height: 30px;
    }
}
.wrapper{
    position: relative;
    padding: 15px;
    background: rgb(30, 30, 33);
    border-radius: 10px;
    width: 100%;


    .table{
        width: 100%;
        background: #2F3033;
        border-radius: 10px;
        border-collapse: collapse;
        

        .head{
            tr{
                td{
                    padding: 10px 16px;
                    color: rgb(163, 184, 224);
                    border-bottom: 1px #494848 solid;
                    font-size: 16px;
                }
            }
        }

        .body{
            tr{
                border-bottom: 1px #494848 solid;
                transition: .3s;

                &:last-child{
                    border-bottom: none;
                }

                td{
                    padding: 10px 16px;
                    font-size: 14px;
                }
            }
            .link{
                cursor: pointer;
                
                &:hover{
                    background: #1E1E21;
                }
            }
        }
    }

    .total{
        position: absolute;
        bottom: 100%;
        left: 15px;
        background: #2F3033;
        padding: 0px 10px;
        border-radius: 10px 10px 0 0;;
    
        .value{
            font-size: 14px;
            color: #666666;
            opacity: 1;
            visibility: visible;
            display: block;
        }
    }

    .loading{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(2px);
        display: flex;
        justify-content: center;
        align-items: center;
    
        svg{
            animation: rotation 1s infinite linear;
            width: 30px;
            height: 30px;
        }
    }
}


@keyframes rotation{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}