@import "../../../../index.scss";

.pages_modal {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.form {
  @include form;
}

.pages_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wrap_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 16px;
  margin-left: auto;
  padding-top: 20px;
  width: max-content;
}