.main {
  width: 100%;
  height: 100%;
  display: flex;
  background: var(--Main-pallet-Grayscale-100, #161618);
  min-height: 100vh;
  max-width: 100vw;

  .sidebar{
    width: 260px;
    flex-shrink: 0;
  }

  .main_content {
    flex: 1 0 auto;
    min-height: 100vh;
    position: relative;
    padding: 24px;
  }
}
