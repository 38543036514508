.back {
  display: flex;
  gap: 10px;
  color: var(--Main-pallet-Grayscale-50, #767676);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 31.2px */
  letter-spacing: 0.24px;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}