.wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    position: relative;
    min-height: 183px;

    .buttons_wrapper{
        display: flex;
        gap: 25px;
        overflow-x: auto;
        scrollbar-width: thin;
        padding: 10px 10px 20px 10px;
        width: 100%;
    
        .button{
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px;
            background: #1E1E21;
            border-radius: 10px;
            cursor: pointer;
            min-width: 250px;
            border: 1px transparent solid;
            transition: .3s;
            position: relative;

            .color{
                position: absolute;
                top: 10px;
                right: 10px;
                width: 40px;
                height: 20px;
                background: #FFF;
                border-radius: 10px;
            }
    
            &:hover{
                border: 1px #464444 solid;
            }
    
            .title{
                font-size: 18px;
                font-weight: 500;
                text-transform: capitalize;
            }
    
            .values{
                display: flex;
                align-items: center;
                gap: 10px;
    
                .arrow_wrapper{
                    transform: rotate(45deg);
                    svg{ 
                        path{
                            stroke: #807e7e;;
                        }
                    }
                }
    
                .value{
                    font-size: 32px;
                    font-weight: 600;
                }
    
                .procent{
                    color: #807e7e;
                    font-size: 16px;
                }
    
                .positive_arrow{
                    transform: rotate(0deg);
                    svg{ 
                        path{
                            stroke: green;;
                        }
                    }
                }
    
                .active_procent{
                    color: green;
                }

                .per{
                    text-transform: lowercase;
                    color: #6d6c6c;
                }
            }
        }
    
        .active_button{
            box-shadow: 0 0 8px 0 #EC7C40;
        }
    }

    .timerange_wrapper {
        display: flex;
        padding: 3px;
        border: 1px #c4c4c4 solid;
        border-radius: 4px;
        margin-left: 8px;
        max-width: max-content;

        .timerange {
          font-size: 14px;
          line-height: 19px;
          color: #7e7e7e;
          padding: 4px 16px;
          font-weight: 600;
          border-radius: 2px;
          transition: 0.2s;
          cursor: pointer;
          user-select: none;
        }
        .timerange_active {
          color: #fefefe;
          background: #EC7C40;
        }
    }
}
