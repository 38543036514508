.wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    position: relative;

    .content{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}