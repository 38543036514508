.wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 45px 0px;
    position: relative;

    .button_wrapper{
        position: absolute;
        top: -30px;
        right: 0;
    }

    .group{
        display: flex;
        flex-direction: column;
        background: #2F3033;
        border-radius: 15px;
        padding: 20px;
        position: relative;

        .header{
            display: flex;
            justify-content: space-between;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            padding: 20px 20px 0px 20px;

            .created{
                color: #717D9D;
            }

            .menu_wrapper{
                cursor: pointer;
                position: relative;

                svg{
                    pointer-events: none;
                }

                .menu{
                    position: absolute;
                    top: 100%;
                    right: 100%;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    background: #2F3033;
                    box-shadow: 0px 0px 10px 10px #242323;
                    opacity: 0;
                    visibility: hidden;

                    .back{
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100vw;
                        height: 100vh;
                        z-index: 2;
                    }

                    .menu_item{
                        padding: 10px;
                        transition: .2s;
                        user-select: none;
                        z-index: 3;
                        
                        &:hover{
                            background: #38383b;
                        }

                        &:nth-child(2n){
                            border-radius: 10px 10px 0 0;
                        }

                        &:last-child{
                            border-radius: 0 0 10px 10px;
                        }
                    }
                }
                .active{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .content{
            display: flex;

            .left{
                width: 80%;
                display: flex;
                align-items: center;

                .name{
                    color: #ec7c40;
                    font-size: 20px;
                    height: fit-content;
                    width: fit-content;
                    cursor: pointer;
                }
            }

            .right{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 20%;
                padding-top: 20px;

                .title{
                    font-size: 18px
                }
                .value{
                    color: #ec7c40;
                    font-weight: 700;
                    font-size: 36px;
                }
            }
        }
    }
}