@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal_wrapper, .modal_wrapper_array {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(133, 133, 133, 0.20);
  backdrop-filter: blur(3.5px);
  padding-left: 240px;
  z-index: 20;

  .modal {
    background: var(--Main-pallet-Grayscale-90, #1E1E21);
    margin-bottom: 20px;
    border-radius: 20px;
    animation: fadeIn 0.5s ease-in-out;
    display: flex;
    width: 490px;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
    flex-shrink: 0;
    box-sizing: border-box;

    &.modal_module {
      gap: 16px;
    }

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .formModule {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &_description {
      color: var(--Main-pallet-Grayscale-30, #CCC);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px; /* 127.778% */
      margin: 0 auto;

      span {
        color: var(--Main-pallet-Accent-100, #EC7C40);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.16px;
      }
    }

    h1 {
      text-align: center;
      align-self: stretch;
      color: #EC722E;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 41.6px */
      letter-spacing: 0.32px;
    }

    .image_wrapper {
      width: 100px;
      height: 100px;
      align-self: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .buttons_wrapper {
      width: 100%;
      display: flex;
      gap: 10px;
    }

    .buttons_wrapper_module {
      width: 100%;
      display: flex;
      gap: 10px;
      padding-top: 40px;
    }
  }
}
