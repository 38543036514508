.wrap {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
  z-index: 2000;
  pointer-events: all;
  padding: 0 100px;
}

.progress_wrapper {
  width: 60%;
  height: 24px;
  box-shadow: 0px 3px 3px 0px #00000040 inset;
  border-radius: 12px;
  background: #292929;
}

.text {
  position: absolute;
  top: 40%;
  color: #F7F8FF;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
}

.hint {
  position: absolute;
  top: 45%;
  color: brown;
}

.progress_line {
  width: 200px;
  height: 100%;
  position: relative;
  border-radius: 12px;
  transition: 1s;
}

.progress_gradient {
  height: 100%;
  width: 100%;
  background: linear-gradient(89.74deg, #B64E17 0.22%, #C35C25 53.88%, #A6CFF7 110.85%);
  border-radius: 12px;
  box-shadow: 0px 3px 4px 0px #FFFFFF80 inset;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0px -2px 4px 0px #00000040 inset;
    border-radius: 12px;
  }
}

.progress_shadow {
  height: 100%;
  width: 100%;
  background: linear-gradient(89.74deg, #0C3483 0.22%, #6B8CCE 53.88%, #A2B6DF 110.85%);
  border-radius: 12px;
  position: absolute;
  filter: blur(10px);
}
