.wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 50px;

    .title{
        color: #EC7C40;
        text-align: center;
        font-size: 24px;
    }

    .header{
        display: flex;
        justify-content: flex-end;

        .timerange_wrapper {
            display: flex;
            padding: 3px;
            border: 1px #c4c4c4 solid;
            border-radius: 4px;
            margin-left: 8px;
            max-width: max-content;
    
            .timerange {
              font-size: 14px;
              line-height: 19px;
              color: #7e7e7e;
              padding: 4px 16px;
              font-weight: 600;
              border-radius: 2px;
              transition: 0.2s;
              cursor: pointer;
              user-select: none;
            }
            .timerange_active {
              color: #fefefe;
              background: #EC7C40;
            }
        }
    }

    .table_wrapper{
        position: relative;
    }
}