.wrapper{
    display: flex;
    align-items: center;
    gap: 10px;

    .dec, .inc{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #464647;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        cursor: pointer;
        user-select: none;
        border: 1px transparent solid;
        transition: .2s;

        &:hover{
            border: 1px #C26837 solid;
            // color: #C26837;
        }
    }

    .input{
        width: 64px !important;
        height: 41px !important;
        border-radius: 8px !important;
        padding: 0 !important;
        text-align: center;
        display: flex;
        justify-content: center;
    }
}