.card {
  display: flex;
  width: 200px;
  height: 210px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--Main-pallet-Grayscale-90, #1E1E21);
  padding: 10px;

  &_wrap {
    display: flex;
    width: 200px;
    padding: 0 10px 0 10px;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
  }

  &_image {
    width: 100%;
    height: 120px;
    position: relative;

    .delete {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      transition: .3s;

      &:hover {
        path {
          stroke: #FF0004;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 10px;
    }
  }

  &_title {
    color: var(--Main-pallet-Grayscale-0, #E8E9E9);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 3px;


    .price {
      color: var(--Main-pallet-Grayscale-0, #E8E9E9);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      gap: 5px;

      span {
        color: var(--Main-pallet-Accent-100, #EC7C40);
      }
    }
  }
}