.wrap_blog {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-left: 8px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}