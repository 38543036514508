.wrapper{
    display: flex;
    padding: 15px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--Main-pallet-Grayscale-80, #232428);
    height: 100%;

    .title{
        color: var(--Main-pallet-Accent-100, #EC7C40);
        font-size: 18px;
        font-weight: 600;
    }

    .image_wrapper{
        background: #14161D;
        border-radius: 12px;
        width: 305px;
        height: 213px;
        margin: 0 auto;
        cursor: pointer;
        position: relative;

        input{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
}