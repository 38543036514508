.dropdown {
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    color: var(--Main-pallet-Grayscale-50, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    margin-bottom: -6px;
  }

  .switch {
    display: flex;
    gap: 5px;
  }
}

.input_container {
  display: flex;
  padding: 16px 0px;
  gap: 10px;
  align-items: center;
  align-self: stretch;

  .button {
    display: flex;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 3px;
    background: unset;
    color: #EC722E;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    letter-spacing: 0.16px;
    outline: transparent;
    transition: all 0.5s ease;
    border: 1px solid transparent;

    .icon_add path {
      stroke: #EC722E;
    }

    &:hover {
      cursor: pointer;
      border: 1px solid #EC722E;
    }
  }
}

.input_content {
  display: flex;
  align-items: center;
  gap: 5px;

  .value {
    display: flex;
    padding: 5px 10px;
    align-items: center;
    gap: 4px;
    border-radius: 20px;
    border: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
    background: var(--Main-pallet-Accent-90, #EC722E);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset;
    color: var(--Main-pallet-Grayscale-70, #2D2D2D);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */

    .icon_delete:hover {
      cursor: pointer;
    }
  }
}

.content, .content_row {
  display: flex;
  padding: 15px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Main-pallet-Grayscale-80, #232428);
  height: 100%;

  .arrow {
    transform: rotate(180deg);
  }

  &.hide {
    overflow: hidden;
    height: 54px;
    .arrow {
      transform: rotate(0);
    }
  }

  &.none {
    display: none;
  }

  &_head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .arrow {
      &:hover {
        cursor: pointer;

      }
    }

    p {
      color: var(--Main-pallet-Grayscale-0, #FFF);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 23.4px */
      letter-spacing: 0.18px;
      margin: 0;
    }

    span {
      color: var(--Main-pallet-Accent-100, #EC7C40);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 23.4px */
      letter-spacing: 0.18px;
    }
  }

  &_button {
    width: 180px;
  }
}

.content_row {
  flex-direction: row;

  .flex_column {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    align-items: center;

    color: var(--Main-pallet-Grayscale-0, #FFF);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 23.4px */
    letter-spacing: 0.18px;

    .top_checkbox {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    span {
      color: var(--Main-pallet-Accent-100, #EC7C40);
    }
  }
}

.error_text {
  font-size: 13px;
  color: red;
}

.image_wrapper {
  width: 160px;
  height: 160px;
  border-radius: 5px;
  background-color: #27282E;
  box-shadow: 1px 1px 13px -7px #000;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.video_wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.no_elements {
  font-size: 14px;
  margin-top: 40%;
}

.preview_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.change_input {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  position: absolute;

  &:hover {
    cursor: pointer;
  }
}

.default_wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 6px;

  span {
    color: var(--Main-pallet-Grayscale-0, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.16px;
  }
}

.lang_switcher {
  display: flex;
  gap: 5px;
  align-items: center;
}

.translateIcon {
  width: 25px;
  height: 25px;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }
}
.loadingIcon {
  width: 25px;
  height: 25px;
  animation: loading_rotate 2s infinite linear;
}
@keyframes loading_rotate{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
.lang_button {
  border-radius: 20px;
  border: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
  background: unset;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset;
  display: flex;
  padding: 5px 10px;
  align-items: center;
  gap: 4px;
  transition: .3s;

  .check_icon {
    width: 15px;
    height: 15px;
  }

  &.active {
    background: var(--Main-pallet-Accent-90, #EC722E);
  }

  &:hover {
    cursor: pointer;
  }
}