.wrap_imageUploader{
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title{
    color: var(--Main-pallet-Accent-90, #EC722E);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }

  .images_wrapper{
    display: flex;
    align-items: flex-start;
    gap: 11px;
    max-width: calc(100vw - 350px);

    .add_button{
      width: 160px;
      height: 109px;
      border: 1px #EC7C40 dashed;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1 0 auto;
      cursor: pointer;
      position: relative;

      svg{
        path{
          stroke: #EC7C40;
        }
      }

      .add{
        font-size: 12px;
        color: #EC7C40;
      }
      .mimetypes{
        margin-top: 5px;
        font-size: 10px;
        color: #C4C4C4;
      }
      input{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: pointer;
        opacity: 0;
      }
      .loading{
        animation: spin 1s infinite linear;
        max-width: 50%;
        position: absolute;
        top: 45%;
        left: 45%;
        transform: translate(-50%, -50%);
        color: #14161D;
      }
      .white_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #FFF;
        border-radius: 8px;
      }
    }

    .selected_images{
      display: flex;
      gap: 11px;
      width: 100%;
      height: 120px;
      overflow-x: auto;
      overflow-y: hidden;


      &::-webkit-scrollbar {
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--Main-pallet-Accent-70, #B64E17);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background: var(--Main-pallet-Grayscale-60, #3D3D3D);
        border-radius: 10px;
      }

      .image{
        width: 160px;
        max-width: 160px;
        height: 109px;
        max-height: 109px;
        border-radius: 8px;
        position: relative;
        flex: 1 0 auto;
        border: 1px #e0e0e0 solid;

        .delete_wrapper{
          position: absolute;
          top: 4px;
          right: 4px;
          cursor: pointer;
          width: 22px;
          height: 22px;
          border-radius: 50%;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        .main{
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 23px;
          padding: 4px 8px;
          background: rgba(38, 38, 38, 0.5);
          border-radius: 0px 0px 8px 8px;

          span{
            font-size: 12px;
            color: #FDFBF7;
          }
        }

        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media(max-width: 550px){
  .wrap_imageUploader{
    .images_wrapper{
      .add_button{
        width: 100px;
        height: 70px;
      }
      .selected_images{
        height: 80px;

        .image{
          width: 100px;
          max-width: 100px;
          height: 70px;
          max-height: 70px;
        }
      }
    }
  }
}
