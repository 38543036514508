.wrap_edit {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.content {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  border-radius: 15px;
  background: var(--Main-pallet-Grayscale-90, #1E1E21);

  &_title {
    color: var(--Main-pallet-Accent-100, #EC7C40);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 41.6px */
    letter-spacing: 0.32px;
  }
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wrap_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 16px;
  margin-left: auto;
  padding-top: 20px;

  button {
    width: auto;
  }
}


.label {
  display: flex;
  align-items: center;
  gap: 5px;
}


.additional_title {
  color: var(--Main-pallet-Accent-100, #EC7C40);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
  letter-spacing: 0.18px;
}

.additional_button_add {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  gap: 4px;
  border-radius: 5px;
  border: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
  background: var(--Main-pallet-Accent-90, #EC722E);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset;
  width: fit-content;
  color: var(--Main-pallet-Grayscale-70, #2D2D2D);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */;

  &:hover {
    cursor: pointer;
  }
}

.field {
  display: flex;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Main-pallet-Grayscale-80, #232428);

  &_size {
    width: 30px;
    height: 30px;
  }

  &_picker {
    position: absolute;
    z-index: 2;
    margin-top: -400px;
  }

  &_picker_close {
    cursor: pointer;
    float: right;
    margin: 5px 1px;

    path {
      stroke: #14161D;
    }
  }

  &_input {
    padding: 8px !important;
  }

  &_color {
    width: 30px;
    height: 28px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 auto;
  }

  &_delete {
    width: 25px;
    height: 25px;

    &:hover {
      cursor: pointer;
    }
  }
}