.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .inputContainer {
    position: relative;
  }

  label {
    color: var(--Main-pallet-Grayscale-50, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }

  .iconInput, .iconInputEye {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 24px;
    top: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .iconInputEye {
    top: 16px;
    width: 24px;
    height: 24px;
  }

  .error_text {
    font-size: 13px;
    color: red;
  }
}