.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.content {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px;
  background: var(--Main-pallet-Grayscale-90, #1E1E21);

  &_title {
    color: var(--Main-pallet-Accent-100, #EC7C40);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 41.6px */
    letter-spacing: 0.32px;
  }
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wrap_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 16px;
  margin-left: auto;
  padding-top: 20px;

  button {
    width: auto;
  }
}
