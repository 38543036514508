.page{
    display: flex;
    flex-direction: column;
    padding: 30px 0 50px 0;
    max-width: 100%;
    position: relative;
    gap: 20px;

    .header{
        background: #1E1E21;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        justify-content: space-between;

        .item{

            .title{
                color: #EC7C40;
                font-size: 18px;
            }
            .value{
                color: #717D9D;;
                font-size: 18px;
            }
        }

        .timerange_wrapper {
            display: flex;
            padding: 3px;
            border: 1px #c4c4c4 solid;
            border-radius: 4px;
            margin-left: 8px;
            max-width: max-content;
    
            .timerange {
              font-size: 14px;
              line-height: 19px;
              color: #7e7e7e;
              padding: 4px 16px;
              font-weight: 600;
              border-radius: 2px;
              transition: 0.2s;
              cursor: pointer;
              user-select: none;
            }
            .timerange_active {
              color: #fefefe;
              background: #EC7C40;
            }
        }
    }

    .content{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}