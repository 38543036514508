.wrapper{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;

    .back{
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        backdrop-filter: blur(10px);
    }

    .modal{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #2F3033;
        border-radius: 10px;
        min-width: 500px;
        box-shadow: 0px 0px 10px 5px #222222;
        max-height: 90vh;

        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px #383737 solid;
            padding: 20px;
    
            .title{
                font-size: 18px;
                font-weight: 500;
            }
    
            .close_wrapper{
                width: 20px;
                height: 20px;
                cursor: pointer;
    
                svg{
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .content{
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 20px;
            position: relative;

            .error{
                color: red;
            }
        }
    }
}