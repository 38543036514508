.wrapper{
    width: calc(16.6% - 10px);
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #161618;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    transition: .2s;

    &:hover{
        background: #1d1d20;

        .delete_button{
            opacity: 1;
            visibility: visible;
        }

        .copy_button{
            opacity: 1;
            visibility: visible;
        }

        .select{
            opacity: 1;
            visibility: visible;
        }
    }

    .select{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40%;
        backdrop-filter: blur(2px);
        background: rgba(236, 124, 64, 0.2);
        opacity: 0;
        visibility: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s;

        &:hover{
            background: rgba(236, 124, 64, 1);
        }
    }

    .type{
        position: absolute;
        top: 22px;
        left: 5px;
        border-radius: 5px;
        color: #FFF;
        font-size: 9px;
        padding: 0 2px;
        border: 1px #FFF solid;
        background: #000;
    }

    .size{
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 5px;
        color: #FFF;
        font-size: 9px;
        padding: 0 2px;
        border: 1px #FFF solid;
        background: #000;
    }

    video{
        border-radius: 15px;
        width: 100%;
        height: 100%;
    }

    .name{
        display: block;
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 15px;
    }

    .delete_button{
        width: 25px;
        height: 25px;
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        transition: .3s;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #232428;
        border-radius: 50%;

        &:hover{
            svg{
                path{
                    stroke: red;
                }
            }
        }

        svg{
            width: 15px;
            height: 15px;
            path{
                stroke: #FFF;
            }
        }
    }

    .copy_button{
        width: 25px;
        height: 25px;
        position: absolute;
        top: 25px;
        right: 0px;
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        transition: .3s;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #232428;
        border-radius: 50%;

        &:hover{
            svg{
                path{
                    stroke: #EC7C40;
                    fill: #EC7C40;
                }
            }
        }

        svg{
            width: 15px;
            height: 15px;
            
            path{
                stroke: #FFF;
                fill: #FFF;
            }
        }
    }

    .preview{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70vw;
        height: 70vh;
        z-index: 100;
        display: flex;
        flex-direction: column;
        background: #000;

        .header{
            padding: 20px;
            background: #141313;
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: 100%;
            width: 100%;
        }

        .container{
            width: 100%;
            height: 100%;

            video{
                width: 100%;
            }
        }
    }

    .back{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        backdrop-filter: blur(12px);
        z-index: 100;
    }
}