.dropdown{
    display: grid;
    grid-template-rows: 0fr;
    transition: .5s;
    overflow: hidden;
    padding: 0;
    width: 100%;

    .content{
        min-height: 0px;
        opacity: 0;
        transition: .5s;
    }
}

.open{
    grid-template-rows: 1fr;
    padding: 20px 0;

    .content {
        opacity: 1;
    }
}