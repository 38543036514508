.wrapper{
    display: flex;
    flex-direction: column;
    gap: 30px;

    .content{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .category{
            padding: 20px;
            border-radius: 16px;
            border: 1px #CCC solid;
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 15px;

            .tools{
                position: absolute;
                top: 15px;
                right: 15px;
                cursor: pointer;
            }

            .row{
                display: flex;
                flex-direction: column;

                .title{
                    color: #EC7C40;
                    font-weight: 700;
                    font-size: 18px;
                }

                .value{
                    font-size: 16px;
                }
            }
        }
    }

    .footer{
        width: fit-content;
    }
}