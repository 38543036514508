.wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 16px;
    padding: 30px 0px;

    .content_wrapper{
        display: flex;

        .left{
            width: 40%;
            background: #1E1E21;
            padding: 24px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            position: relative;

            .row{
                display: flex;
                flex-direction: column;
                gap: 10px;
                background: #232428;
                padding: 10px;
    
                .title{
                    font-size: 16px;
                    color: #717D9D;
                }
            }

            .checkbox_row{
                display: flex;
                -moz-box-align: center;
                align-items: center;
                gap: 16px;
                width: fit-content;

                .checkbox_wrapper{
                    display: flex;
                    -moz-box-align: center;
                    align-items: center;
                    gap: 8px;
                    width: fit-content;
                    white-space: nowrap;
                }

                input{
                    padding: 10px !important;
                }
            }
        }
        .right{
            width: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: content-box;

            .frame{
                border: 1px #424A61 solid;
                border-radius: 20px;
                height: fit-content;
                box-sizing: content-box;
                width: 700px;
            }
        }
    }

    .footer{
        display: flex;
        gap: 30px;
        justify-content: center;
    }
}

.loading_wrapper{
    position: relative;
    width: 100%;
    height: 500px;
}