
.mailing{
    display: flex;
    flex-direction: column;
    background: #1E1E21;
    border-radius: 16px;
    padding: 16px 32px;
}
// ### Header
.header{
    display: flex;
    justify-content: space-between;
}

.date_wrapper{
    display: flex;
    gap: 20px;

    span{
        color: #ec7c40;
        font-size: 14px;
    }
}

.header_date{
    color: #717D9D;
    font-size: 14px;
    margin: 0;
    display: flex;
    gap: 5px;
}

.date_wrapper{
    color: #ec7c40;
}

.icon_wrapper{
    width: 20px;
    height: 20px;

    svg{
        width: 20px;
        height: 20px;
    }
}

.rotate{
    animation: 3s rotate linear infinite;
}

.menu_wrapper{
    position: relative;
    width: 20px;
    height: 20px;

    svg{
        width: 22px;
        height: 22px;
        fill: #FFF;
    }
}

.icon_wrapper{
    cursor: pointer;
    width: 18px;
    height: 18px;
    z-index: 3;
}

.header_menu{
    display: flex;
    flex-direction: column;
    padding: 4px;
    position: absolute;
    top: 100%;
    right: 100%;
    background: #2F3033;
    box-shadow: 0px 0px 10px 10px #242323;
    border-radius: 5px;
}

.close_wrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

.menu_item{
    display: flex;
    align-items: center;
    padding: 6px 10px;
    gap: 8px;
    user-select: none;
    z-index: 2;
    cursor: pointer;
    transition: .3s;
    border-radius: 5px;

    svg{
        width: 18px;
        height: 18px;
    }

    &:hover{
        background: #424A61;
    }
}

// ### Content

.content{
    display: flex;
    align-items: center;
    margin: 41px 0 33px 0;
}

.name{
    display: flex;
    align-items: center;
    margin: 0;
    color: #ec7c40;;
    font-size: 36px;
    font-weight: 600;
    flex: 1 0 auto;
    
    span{
        cursor: pointer;
    }
}

.statistics_wrapper{
    display: flex;
    align-items: center;
    gap: 80px;
}

.statistics_item{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.value{
    color: #ec7c40 !important;
    margin: 0;
    font-weight: 700;
    font-size: 36px;
}

.title{
    color: #FFFFFF;
    margin: 0;
    font-size: 14px;
}

// ### Progress

.progress_wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
}

.procent{
    color: #ec7c40;
    font-size: 24px;
}


@keyframes rotate{
    0%{
        transform: rotate(360deg);
    }
    100%{
        transform: rotate(0deg);
    }
}