.primary {
  width: 100%;
  display: flex;
  height: 48px;
  padding: 12px 25px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Main-pallet-Accent-90, #EC722E);
  background: #EC722E;
  color: #F7F8FF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  letter-spacing: 0.16px;
  transition: all 0.5s ease;

  &:hover {
    cursor: pointer;
    background: var(--Main-pallet-Accent-100, #EC7C40);
    color: #F7F8FF;

    svg path {
      stroke: #F7F8FF;
    }
  }

  &:disabled {
    background: var(--Main-pallet-Grayscale-70, #2D2D2D);
    border: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
    color: var(--Main-pallet-Grayscale-50, #767676);

    svg path {
      stroke: #767676;
    }

    &:hover {
      cursor: not-allowed;
    }

    &:active {
      background: var(--Main-pallet-Grayscale-70, #2D2D2D);
      border: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
      color: var(--Main-pallet-Grayscale-50, #767676);

      svg path {
        stroke: #767676;
      }
    }
  }

  &:active {
    background: var(--Main-pallet-Accent-80, #C35C25);
    color: #F7F8FF;

    svg path {
      stroke: #F7F8FF;
    }
  }
}