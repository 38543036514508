/* Стили для skeleton-загрузки */
.skeleton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1E1E21;
    overflow: hidden;
  }
  
  /* Анимация волны */
  .skeleton::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(224, 224, 224, 0) 0%, rgba(236, 124, 64, 0.3) 50%, rgba(224, 224, 224, 0) 100%);
    animation: skeleton-wave 1.5s infinite ease-in-out;
  }
  
  @keyframes skeleton-wave {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  /* Пример использования */
  .skeleton-text {
    width: 100%;
    height: 16px;
    border-radius: 4px;
  }
  
  .skeleton-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  } 
  