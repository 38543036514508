.wrap_documents {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-left: 10px;
}

.list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.card {
  display: flex;
  width: 250px;
  height: 300px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background: var(--Main-pallet-Grayscale-100, #161618);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
  gap: 8px;
  position: relative;
  border: 1px solid #1E1E21;

  &:hover {
    background: var(--Main-pallet-Grayscale-90, #1E1E21);

    .edit {
      opacity: 1;
    }
  }
  &_top {
    width: 100%;
    background: var(--Main-pallet-Grayscale-80, #232428);
    height: 30px;
    border-radius: 10px 10px 0 0 ;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 5px;
    cursor: pointer;

    .check {
      width: 20px;
      height: 20px;

      path {
        stroke: #767676;
      }
    }

    p {
      color: #EC722E;
    }

    span {
      color: #767676;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px; /* 200% */
    }

    .delete {
      position: absolute;
      right: 5px;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }

    .copy {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }

  &_main {
    padding: 5px 5px 5px 5px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    h3 {
      color: var(--Main-pallet-Grayscale-0, #E8E9E9);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 28.8px */
      align-self: center;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      text-align: left;
    }

    p {
      color: var(--Braun-80, #6D6D6D);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 12;
      line-clamp: 12;
      -webkit-box-orient: vertical;
      text-align: left;
    }

    .edit {
      cursor: pointer;
      position: absolute;
      bottom: 43%;
      right: 43%;
      width: 30px;
      height: 30px;
      opacity: 0;
      transition: .3s;
    }
  }
}