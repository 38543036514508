.wrapper{
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .delete_wrapper{
        width: 20px;
        height: 20px;

        svg{
            width: 20px;
            height: 20px;
        }
    }
}