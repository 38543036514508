.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.name_type {
  color: var(--Main-pallet-Grayscale-0, #FFF);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
  letter-spacing: 0.18px;
}

.form {
  width: 100%;
  padding: 20px;
  border-radius: 15px;
  background: var(--Main-pallet-Grayscale-90, #1E1E21);

  h2 {
    color: var(--Main-pallet-Accent-100, #EC7C40);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 41.6px */
    letter-spacing: 0.32px;
    margin-bottom: 30px;
  }
}

.container_elements {
  border-radius: 8px;
  border: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.main_info {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .type {
      color: var(--Main-pallet-Accent-100, #EC7C40);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 23.4px */
      letter-spacing: 0.18px;
    }
}

.type_style {
  border-radius: 5px;
  background: #28292E;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  padding: 15px;
}

.type_array {
  border-radius: 8px;
  border: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
  padding: 20px;
}

.wrap_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-left: auto;
  max-width: max-content;

  button {
    width: auto;
  }
}