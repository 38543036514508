.content {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  border-radius: 15px;
  background: var(--Main-pallet-Grayscale-90, #1E1E21);

  table {
    width: 100%;

    tr {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

      &.first {
        border-bottom: 1px solid var(--Main-pallet-Grayscale-60, #3D3D3D);

        td {
          color: var(--Main-pallet-Grayscale-10, #EEEFF1);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%; /* 16px */
          letter-spacing: 0.16px;
        }
      }

      td {
        padding: 8px 24px 8px 0;
        color: var(--Main-pallet-Grayscale-30, #CCC);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
    }
  }
}

.title {
  color: var(--Main-pallet-Accent-100, #EC7C40);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 41.6px */
  letter-spacing: 0.32px;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .wrap_search {
    position: relative;

    input {
      &:focus {
        border: 2px solid var(--Main-pallet-Accent-100, #EC7C40);
        outline: unset;
      }

      &.error {
        border: 2px solid var(--Main-pallet-Additional-Error, #FF0004);
        outline: unset;
      }
    }

    .searchInput {
      display: flex;
      width: 344px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      background: unset;
    }

  }

  .searchIcon {
    position: absolute;
    right: 24px;
    top: 16px;

    &:hover {
      cursor: pointer;
    }
  }
}
