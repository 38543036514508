.custom_select {
  position: relative;
  width: 100%;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;

  label {
    padding-top: 5px;
    color: var(--Main-pallet-Grayscale-50, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }
}

.selected {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Main-pallet-Grayscale-40, #A9A9A9);
  background: var(--Main-pallet-Grayscale-90, #1E1E21);

  color: var(--Main-pallet-Grayscale-20, #E8E9E9);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;

  svg  {
    width: 20px;
    height: 20px;
    transition: all 0.4s ease;

    path {
      stroke: #E8E9E9;
    }
  }

  &_active {
    border: 1px solid var(--Main-pallet-Accent-100, #EC7C40);

    svg {
      transform: rotate(180deg);
    }
  }

  &.default {
    color: var(--Main-pallet-Grayscale-50, #767676);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.16px;
  }

  &.disable {
    &:hover {
      cursor: not-allowed;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.options {
  position: absolute;
  top: 105%;
  left: auto;
  right: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ccc;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 50;

  display: flex;
  width: 143px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  border: 1px solid var(--Main-pallet-Accent-100, #EC7C40);
  background: var(--Main-pallet-Grayscale-90, #1E1E21);

}

.option {
  cursor: pointer;
  background: var(--Main-pallet-Grayscale-80, #232428);

  display: flex;
  width: 100%;
  padding: 8px 16px 9px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--Main-pallet-Grayscale-90, #1E1E21);
  color: var(--Push-200, #E4E6EC);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.option:hover {
  background: var(--Main-pallet-Grayscale-70, #2D2D2D);
  color: #fff;
}

.radio_items {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  padding-top: 6px;

  .item {
    display: flex;
    padding: 5px 10px;
    align-items: center;
    gap: 4px;
    border-radius: 20px;
    border: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
    background: var(--Main-pallet-Accent-90, #EC722E);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset;

    p {
      color: var(--Main-pallet-Grayscale-70, #2D2D2D);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }

    svg {
      &:hover {
        cursor: pointer;
      }

      path {
        stroke: #2D2D2D;
      }
    }


  }
}
