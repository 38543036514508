
.wrapper{
    max-width: 100%;
    width: 100%;
    height: 24px;
    box-shadow: 0px 3px 3px 0px #00000040 inset;
    border-radius: 12px;
    background: #292929;
    // border: 1px red solid;
}

.line{
    width: 200px;
    height: 100%;
    position: relative;
    border-radius: 12px;
    transition: 1s;
}

.gradient{
    height: 100%;
    width: 100%;
    background: linear-gradient(89.74deg, #EC7C40 0.22%, #f5b694 53.88%, #f8d8c7 110.85%);
    border-radius: 12px;
    box-shadow: 0px 3px 4px 0px #FFFFFF80 inset;
    position: relative;
    
    &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        box-shadow: 0px -2px 4px 0px #00000040 inset;
        border-radius: 12px;
    }
}

.shadow{
    height: 100%;
    width: 100%;
    background: linear-gradient(89.74deg, #EC7C40 0.22%, #f1a277 53.88%, #f0c0a6 110.85%);
    border-radius: 12px;
    position: absolute;
    filter: blur(10px)
}