
.page{
    display: flex;
    padding: 30px 0;
    position: relative;
}

.left{
    width: 35%;
    background: #1E1E21;
    padding: 24px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
}

.title{
    color: #A3B8E0 !important;
    font-size: 24px;
    text-align: center;
    white-space: nowrap;
}

.row{
    background: #232428;
    width: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;

    .title{
        color: #B6BCCD !important;
        font-size: 18px;
    }
}



.value{
    color: #A3B8E0 !important;
    font-size: 18px;

    p{
        text-align: right;
        color: #A3B8E0 !important;
        font-size: 16px;
    }
}

.statistics_wrapper{
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: #232428;
    border-radius: 24px;
    padding: 8px 16px;
}

.statistics{
    display: flex;
    justify-content: space-evenly;

    .item{
        display: flex;
        flex-direction: column;
        align-items: center;

        .statistics_title{
            color: #FFFFFF;
            margin: 0;
            font-size: 14px;
        }

        .statistics_value{
            color: #ec7c40 !important;
            margin: 0;
            font-weight: 700;
            font-size: 36px;
        }
    }
}

.right{
    width: 65%;
    display: flex;
    justify-content: center;
}

.frame{
    border: 1px #424A61 solid;
    border-radius: 20px;
    height: fit-content;
}

.buttons_wrapper{
    position: absolute;
    top: 24px;
    left: 24px;
    display: flex;
    gap: 16px;
}

.process_wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
}

.procent_value{
    color: #ec7c40;
    font-size: 20px;
}
