.wrapper{
    display: flex;
    flex-direction: column;
    gap: 30px;

    .content{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .category{
            width: 300px;
            height: 215px;
            border-radius: 16px;
            border: 1px #CCC solid;
            position: relative;

            .title{
                color: #FDFEFE;
                position: absolute;
                bottom: 20px;
                left: 10px;
                font-size: 18px;
                font-weight: 700;
                padding: 5px 10px;
                border-radius: 20px;
                backdrop-filter: blur(2px);
            }
    
            .background{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 16px;
            }

            .tools{
                position: absolute;
                top: 15px;
                right: 15px;
                cursor: pointer;

                .bg{
                    background: #1E1E21;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg{
                        // fill: red;
                        path{
                            stroke: #FFF;
                        }
                    }
                }
            }
        }
    }

    .footer{
        width: fit-content;
    }
}