.wrapper{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px 0;

    .header{
        display: flex;
        justify-content: space-between;
        background: #1E1E21;
        border-radius: 10px;
        padding: 20px;
        align-items: center;

        .left{
            display: flex;
            align-items: center;
            gap: 50px;
        }

        .back_arrow{
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 18px;
            border-radius: 10px;
            padding: 10px;
            transition: .3s;
            user-select: none;

            &:hover{
                background: #161618;
            }

            svg{
                transform: rotate(90deg);
                width: 30px;
                height: 30px;
            }
        }

        .left_arrow, .right_arrow{
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 18px;
            border-radius: 10px;
            padding: 10px;
            transition: .3s;
            user-select: none;

            &:hover{
                background: #161618;
            }

            svg{
                width: 30px;
                height: 30px;
                transform: rotate(90deg);
            }
        }

        .right_arrow{
            
            svg{
                width: 30px;
                height: 30px;
                transform: rotate(-90deg);
            }
        }

        .email{
            font-size: 22px;
            color: #EC7C40;
            position: relative;
        }
    }

    .content{
        display: flex;
        background: #1E1E21;
        border-radius: 10px;
        padding: 20px;
        position: relative;

        .left{
            width: 50%;
            display: flex;
            gap: 50px;

            .titles{
                display: flex;
                flex-direction: column;
                gap: 20px;

                .title{
                    color: #EC7C40;
                    font-size: 18px;
                }
            }

            .values{
                display: flex;
                flex-direction: column;
                gap: 20px;
                flex: 1 0 auto;

                .value{
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    img{
                        width: 20px;
                        height: 20px;
                        transform: translateY(2px);
                    }
                }
            }
            
        }
    }

    .dropdown_block{
        display: flex;
        flex-direction: column;
        background: #1E1E21;
        border-radius: 10px;
        padding: 20px;
        position: relative;

        .header{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            padding: 0;
            cursor: pointer;

            .arrow{
                width: 30px;
                height: 30px;
                transition: .5s;

                svg{
                    width: 30px;
                    height: 30px;
                }
            }

            .title{
                color: #EC7C40;
                font-size: 18px;
                transition: .5s;
            }
        }

        .active{
            .arrow{
                transform: rotate(180deg);
            }
            .title{
                transform: translateX(20px);
            }
        }
    }
}