.wrapper{
    display: flex;
    flex-direction: column;
    
    .row{
        display: flex;
        gap: 0px;
        align-items: center;
        user-select: none;
        cursor: pointer;
        transition: .2s;
        padding: 10px 0px;
        position: relative;

        &:hover{
            background: #2E2320;
        }

        .arrow_wrapper{
            transform: rotate(-90deg);
            transition: .3s;
            width: 18px;
            min-width: 18px;
            height: 18px;
            pointer-events: none;

            svg{
                width: 18px;
                min-width: 18px;
                height: 18px;
            }
        }

        svg{
            width: 20px;
            min-width: 20px;
            height: 20px;
            user-select: none;
            pointer-events: none;
            // flex: 1 0 auto;
        }

        .name{
            text-overflow: hidden;
            white-space: nowrap;
            pointer-events: none;
            margin-left: 5px;
        }
        .size{
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            text-overflow: hidden;
            white-space: nowrap;
            pointer-events: none;
            margin-left: 5px;
            font-size: 12px;
            color: grey;
        }

    }

    .active{
        background: #2E2320;
        
        .name{
            color: #EC7C40;
        }
    }

    .dropdown{
        overflow: hidden;
        width: 100%;
        height: 0px;
        transition: .3s;
    }
}