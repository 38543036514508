.wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 0px;
    

    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
            display: flex;
            align-items: center;
            gap: 10px;

            .back_wrapper{
                cursor: pointer;

                svg{
                    transform: translateY(3px);
                    width: 25px;
                    height: 25px;
                }
            }
            .title{
                font-size: 22px;
                color: #717D9D;
                font-weight: 700;
            }
        }
        
        .right{
            display: flex;
            align-items: center;
            gap: 20px;

            .changed_all{
                color: #717D9D;
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }
    }

    .content{
        min-height: 400px;

        .contacts{
            padding-top: 20px;
        }
    }
}