.wrap_products {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-left: 8px;
}

.filter_buttons {
  display: inline-flex;
  align-items: center;
  gap: 10px;

  .button {
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    border: 1px solid var(--Main-pallet-Accent-90, #EC722E);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset;
    transition: .3s;

    &_active {
      background: var(--Main-pallet-Accent-90, #EC722E);
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}