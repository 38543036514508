.status {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10000;
  max-width: 90%;
  display: flex;
  width: 412px;
  padding: 12px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: var(--Main-pallet-Grayscale-70, #2D2D2D);
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.14), 0px 6px 30px 0px rgba(0, 0, 0, 0.12), 0px 8px 10px 0px rgba(0, 0, 0, 0.20);
  height: 90px;
  overflow: hidden;

  &.fade_in {
    display: flex;
  }

  &.fade_out {
    display: none;
  }
}

.text_block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;

  p {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.16px;
  }
  span {
    color: var(--Main-pallet-Grayscale-30, #CCC);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
}

.wrap_icon {
  display: inline-flex;
  width: 32px;
  height: 32px;
  padding: 4px;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 43px;
  background: var(--Main-pallet-Grayscale-60, #3D3D3D);
  position: relative;
}

.shadow {
  width: 212px;
  height: 212px;
  position: absolute;
  left: -74px;
  top: -65px;
  z-index: 1;

  &_success {
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 237, 81, 0.12) 0%, rgba(0, 237, 123, 0.00) 100%);
  }

  &_warning {
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 212, 38, 0.11) 0%, rgba(255, 212, 38, 0.00) 100%);
  }

  &_error {
    background: radial-gradient(50% 50% at 50% 50%, rgba(240, 66, 72, 0.13) 0%, rgba(240, 66, 72, 0.00) 100%);
  }
}