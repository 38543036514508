.card {
  display: flex;
  width: 250px;
  height: 300px;
  padding: 5px 5px 5px 5px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background: var(--Main-pallet-Grayscale-80, #232428);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
  gap: 8px;
  position: relative;

  &_image {
    height: 150px;
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
  }

  &_title {
    color: var(--Main-pallet-Grayscale-0, #E8E9E9);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
    align-self: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
  }

  &_description {
    color: var(--Braun-80, #6D6D6D);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    text-align: left;
  }

  &_author {
    display: flex;
    padding: 3px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.40);
    backdrop-filter: blur(6px);
    color: #FFF;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    position: absolute;
    top: 12px;
    left: 12px;
  }

  &_edit {
    position: absolute;
    bottom: 4px;
    right: 4px;
    cursor: pointer;

    path {
      stroke: #EC722E;
    }
  }

  &_delete {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
    width: 25px;
    height: 25px;

    &:hover {
      path {
        stroke: #FF0004;
      }
    }
  }

}