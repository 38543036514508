.wrapper{
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .header{
        display: flex;
        align-items: center;
        gap: 30px;

        .item{
            width: 250px;
            display: flex;
            flex-direction: column;

            input{
                border-width: 1px;
                border-radius: 4px;

                &:focus{
                    outline: 1px #EC7C40 solid;
                }
            }
        }

        .sort_wrapper{
            width: 30px;
            height: 30px;
            cursor: pointer;
            transition: .5s;
            transform: translateY(8px);

            svg{
                width: 30px;
                height: 30px;
                transform-origin: 50% 50%; 
                transition: .5s;
                
                path{
                    transition: 1s;
                }
            }
        }

        .clear_wrapper{
            display: flex;
            align-items: center;
            transform: translateY(8px);
            cursor: pointer;

            svg{
                width: 30px;
                height: 30px;
            }
        }

        .active{

            svg{
                transform: rotate(180deg);
                path{
                    stroke: #EC7C40;
                }
            }

        }

        .pagination_wrapper{
            padding: 20px 0px;
            flex: 1 0 auto;
            display: flex;
            gap: 20px;
            justify-content: flex-end;

            .select_all{
                display: flex;
                align-items: center;
                color: #717D9D;
                font-size: 18px;
            }
        }
    }

    .content{

        .pagination_wrapper{
            padding: 20px 0px;
        }
    }
}