.users {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  td {
    &.icons {
      display: flex;
      gap: 20px;
      justify-content: flex-end;
    }

    .tools_icon {
      cursor: pointer;
      float: right;

      svg {
        pointer-events: none;
        height: 24px;
        width: 24px;
        transition: .3s;

        &:hover {
          path {
            transition: .3s;
            fill: #EC722E !important;
          }
        }
      }
    }
  }
}