@import "../../../../../../index";

.modules {
  //display: flex;
  //flex-direction: column;
  //gap: 20px;
  width: 100%;
}

.module {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
  background: #171719;
}

.module_header {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 100%;

  &_flex {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .main_info {
    display: flex;
    gap: 10px;
    flex-grow: 1;

    .data, .data_small {
      display: flex;
      gap: 5px;
      align-items: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 31.2px */
      letter-spacing: 0.24px;

      p {
        color: #E8E9E9;
      }

      span {
        color: #C35C25;
      }
    }

    .data_small {
      p, span {
        font-size: 12px;
      }
    }
  }
}

.module_values {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.other_type {
  font-size: 16px;
}

.item {
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  border-radius: 8px;
  background: #1E1E21;

  &_name {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    width: 100%;

    .main_info {
      display: flex;
      gap: 10px;
      align-items: flex-start;

      .data, .data_small {
        display: flex;
        gap: 5px;
        align-items: flex-start;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 31.2px */
        letter-spacing: 0.24px;
        p {
          color: #CCCCCC;
        }

        span {
          color: #B64E17;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .data_small {
        p, span {
          font-size: 12px;
        }
      }
    }

  }
}

.menu {
  display: flex;
  width: max-content;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 4px 9.9px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  margin-left: auto;

  top: 48px;
  right: 20px;

  &_item {
    display: flex;
    gap: 15px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 5px 5px 0px 0px;
    border-top: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
    border-right: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
    border-left: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
    background: #27282E;
    color: var(--Main-pallet-Grayscale-10, #EEEFF1);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    z-index: 11;

    &:hover {
      background: var(--Main-pallet-Grayscale-80, #232428);
      cursor: pointer;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.setting {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-left: auto;

  .setting_edit {
    width: 20px;
    height: 20px;
  }

  .setting_arrow {
    transform: rotate(0);
  }
  :hover {
    cursor: pointer;
  }
}

.hide {
  .setting_arrow {
    transform: rotate(-180deg);
  }
}

.module_values_item {
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  border-radius: 8px;
  border: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);

  //&.hide_1 {
  //  height: 54px;
  //  overflow: hidden;
  //  width: 100%;
  //
  //  .setting_arrow {
  //    transform: rotate(0);
  //  }
  //}

  transition: height 0.3s ease;
}

.container, .container_first, .container_second, .container_third {
  width: 100%;
  position: relative;
}

.main_type {
  border-radius: 8px;
  background: var(--Main-pallet-Grayscale-80, #232428);
}

.deletedElement {
  color: var(--Main-pallet-Accent-100, #EC7C40);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.16px;
}

.type {
  border-radius: 4px;
  border: 1px solid var(--Main-pallet-Grayscale-60, #3D3D3D);
  color: var(--Main-pallet-Grayscale-60, #3D3D3D);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;


  &_array {
    border-radius: 4px;
    /*
    border-image: linear-gradient(to right, #910E3866 0, #FFFFFF66 40%) 1;
    */

    background: rgba(61, 61, 61, 0.20);
    backdrop-filter: blur(6.5px);
    color: #910E38;
    border: 0.65px solid #910E3866;
  }

  &_object {
    backdrop-filter: blur(6.5px);
    border-radius: 4px;
    background: rgba(61, 61, 61, 0.20);
    color: #726DB1;
    border: 0.65px solid #726DB1;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 56px;
  border-right: 2px solid #2D2D2D;
  padding-right: 15px;
  height: 100%;

  .lang_block {
    @include lang_block;
  }

  .text_block {
    color: var(--Main-pallet-Grayscale-0, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.16px;
    overflow: hidden;
    max-width: 100vh;

    li {
      list-style-position: inside;
    }
  }

  .img_wrapper {
    width: 160px;
    height: 160px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-height: 100%;
    }
  }
}

.image_wrapper {
  gap: 20px;
  display: flex;
  flex-wrap: wrap;

  .media_wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;

    p {
      font-size: 14px;
      padding-bottom: 5px;
      color: #C35C25;
    }

    img, video {
      width: 160px;
      height: 160px;
      object-fit: cover;
      border-radius: 5px;

    }
  }
}

.button_marker {
  width: auto;
  padding: 5px 10px;

  &_active {
    background: var(--Main-pallet-Accent-90, #EC722E);
    color: var(--Main-pallet-Grayscale-70, #2D2D2D);

    svg {
      path {
        stroke: #2D2D2D;
      }
    }
  }

  &:hover {
    background: var(--Main-pallet-Accent-90, #EC722E);
    color: var(--Main-pallet-Grayscale-70, #2D2D2D);

    svg {
      path {
        stroke: #2D2D2D;
      }
    }
  }
}