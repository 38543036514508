.wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 500px;

    p{
        color: rgb(163, 184, 224);
    }

    header{
        display: flex;
        gap: 10px;
        align-items: center;
        position: relative;

        .langs_wrapper{
            display: flex;
            gap: 8px;

            .lang_item{
                border: 1px #CCC solid;
                border-radius: 4px;
                padding: 5px 8px;
                cursor: pointer;
                display: flex;
                gap: 7px;
                transition: .3s;
                text-transform: uppercase;
                user-select: none;
                font-size: 13px;
                color: #F5F5F5;

                span{
                    user-select: none;
                    cursor: pointer;
                }
            }

            .active_lang_item{
                border: 1px #C26837 solid;
                box-shadow: 0px 0px 8px 1px #C26837;
            }

            .completed_lang_item{
                border: 1px #C26837 solid;
                background: #C26837;
                color: #FFF;
            }
        }
        .translator_wrapper{
            position: relative;
            width: 60px;
            height: 30px;
            transform: translateY(20px);
            position: absolute;
            top: 0;
            right: -15px;
        }
    }

    .input_wrapper{
        display: flex;
        gap: 5px;
        align-items: center;
        align-items: flex-start;

        span{
            text-transform: uppercase;
            font-size: 14px;
            color: #F5F5F5;
        }
    }
}