.secondary {
  width: 100%;
  display: inline-flex;
  padding: 12px 25px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid var(--Main-pallet-Accent-80, #C35C25);
  background: unset;
  box-sizing: border-box;

  color: #C35C25;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.16px;
  transition: all 0.5s ease;

  svg {
    width: 24px;
    height: 24px;
    transition: all 0.5s ease;

    path {
      stroke: #C35C25;
    }
  }

  &:hover {
    cursor: pointer;
    border: 2px solid var(--Main-pallet-Accent-100, #EC7C40);
    color: var(--Main-pallet-Accent-100, #EC7C40);

    svg path {
      stroke: #EC7C40;
    }
  }

  &:disabled {
    background: unset;
    border: 2px solid var(--Main-pallet-Grayscale-60, #3D3D3D);
    color: var(--Main-pallet-Grayscale-60, #3D3D3D);

    &:hover {
      cursor: not-allowed;
    }

    svg path {
      stroke: #3D3D3D;
    }
  }

  &:active {
    border: 2px solid var(--Main-pallet-Accent-80, #C35C25);
    color: var(--Main-pallet-Accent-80, #C35C25);
  }
}

.white {
  border: 2px solid var(--Main-pallet-Grayscale-20, #E8E9E9);
  color: var(--Main-pallet-Grayscale-20, #E8E9E9);
}