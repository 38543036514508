.wrapper{
    width: calc(16.6% - 10px);
    // margin: 5px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #161618;
    border-radius: 15px;
    // display: inline-block;
    padding: 10px 0;
    position: relative;
    cursor: pointer;
    transition: .2s;

    &:hover{
        background: #1d1d20;

        .delete_button{
            opacity: 1;
            visibility: visible;
        }

        .copy_button{
            opacity: 1;
            visibility: visible;
        }
    }

    .type{
        position: absolute;
        top: 22px;
        left: 5px;
        border-radius: 5px;
        color: #FFF;
        font-size: 9px;
        padding: 0 2px;
        border: 1px #FFF solid;
        background: #000;
    }

    .size{
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 5px;
        color: #FFF;
        font-size: 9px;
        padding: 0 2px;
        border: 1px #FFF solid;
        background: #000;
    }

    .name{
        display: block;
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .image_wrapper{
        margin: 0;
        position: absolute;
        top: 10px;
        left: 10px;

        svg{
            width: 20px;
            height: 20px;
        }
    }

    .delete_button{
        width: 25px;
        height: 25px;
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        transition: .3s;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #232428;
        border-radius: 50%;

        &:hover{
            svg{
                path{
                    stroke: red;
                }
            }
        }

        svg{
            width: 15px;
            height: 15px;
            path{
                stroke: #FFF;
            }
        }
    }

    .copy_button{
        width: 25px;
        height: 25px;
        position: absolute;
        top: 25px;
        right: 0px;
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        transition: .3s;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #232428;
        border-radius: 50%;

        &:hover{
            svg{
                path{
                    stroke: #EC7C40;
                    fill: #EC7C40;
                }
            }
        }

        svg{
            width: 15px;
            height: 15px;
            
            path{
                stroke: #FFF;
                fill: #FFF;
            }
        }
    }
}