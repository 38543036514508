.container {
  display: grid;
  grid-template-columns: 1fr 2fr;

  background: radial-gradient(49.59% 25.42% at 50% 50%, #161618 48.44%, #101114 100%);

  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.login {
  padding: 42px 60px;
}

.wrap_form {
 margin-top: 40%;

  h1 {
    color: var(--Main-pallet-Accent-100, #EC7C40);
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 57.6px */
  }
}

.image {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.auth_form {
  display: flex;
  flex-direction: column;
  padding: 40px 0 0 0;
  align-items: center;
  gap: 16px;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px #101114 inset;
    -webkit-text-fill-color: var(--Main-pallet-Grayscale-20, #E8E9E9) !important;
  }

  .error_text {
    font-size: 13px;
    color: red;
  }
}