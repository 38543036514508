.dashboard {
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      color: var(--Main-pallet-Grayscale-60, #3D3D3D);
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 31.2px */
      letter-spacing: 0.24px;
    }
  }

  .title {
    color: var(--Main-pallet-Accent-100, #EC7C40);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 41.6px */
    letter-spacing: 0.32px;
  }

  &_welcome {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    padding: 100px 0 50px;

    p {
      color: var(--Main-pallet-Grayscale-50, #767676);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 31.2px */
      letter-spacing: 0.24px;
    }

    h2 {
      color: var(--Main-pallet-Grayscale-20, #E8E9E9);
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 57.6px */
    }
  }

  &_main {
    display: grid;
    // grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;

    .container {
      display: flex;
      width: 100%;
      padding: 20px 40px;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      border-radius: 12px;
      background: var(--Main-pallet-Grayscale-90, #1E1E21);
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
      max-height: 452px;
      //max-width: 790px;
      min-width: 400px;

      &_overflow {
        overflow: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &_wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        gap: 10px;
        flex-wrap: wrap;

        .section {
          padding: 12px 0;
        }

        .edit {
          color: var(--Main-pallet-Accent-100, #EC7C40);
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 16px */
          letter-spacing: 0.16px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      &_button {
        color: #EC722E;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
        letter-spacing: 0.16px;

        &:hover {
          cursor: pointer;
        }
      }

      .name {
        grid-column: 2;
        grid-row: 1;
        color: var(--Main-pallet-Grayscale-20, #E8E9E9);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 20px */
        letter-spacing: 0.2px;
      }

      &_detail {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto;
        gap: 10px;

        .image {
          width: 60px;
          height: 60px;
          border-radius: 30px;
          grid-column: 1;
          grid-row: 1 / span 2;
          border: 0.8px solid;
          position: relative;

          .letter {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px; /* 128.571% */
            text-transform: uppercase;
          }
        }

        .email {
          grid-column: 2;
          grid-row: 2;
          color: var(--Main-pallet-Grayscale-50, #767676);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: 0.16px;
        }
      }

      &_role {
        display: flex;
        padding: 5px 10px;
        align-items: center;
        gap: 10px;
        border-radius: 20px;
        border: 1px solid var(--Main-pallet-Grayscale-30, #CCC);
        height: auto;

        color: var(--Main-pallet-Grayscale-30, #CCC);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
      }

      &_modules {
        align-items: center;
      }

      .disabled {
        background: #1E1E21;

        .name, .edit {
          color: var(--Main-pallet-Grayscale-60, #3D3D3D);
        }

        .edit:hover {
          cursor: not-allowed;
        }
      }
    }
  }

  .pages {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
    //flex-wrap: wrap;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 45px;

    &::-webkit-scrollbar {
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #2c2f36;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--Main-pallet-Accent-100, #EC7C40);
      border-radius: 4px;
    }

    .page {
      display: flex;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: var(--Main-pallet-Grayscale-40, #A9A9A9);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 20px */
      //border-bottom: 2px solid transparent;
      //margin-top: -17px;
      transition: .3s;
      text-align: center;
      white-space: nowrap;

      &_active {
        color: var(--Main-pallet-Accent-100, #EC7C40);
        //border-bottom: 2px solid var(--Main-pallet-Accent-100, #EC7C40);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

}