.tabs_wrapper{
    display: flex;
    position: relative;
    width: fit-content;

    .item{
        padding: 10px 16px;
        user-select: none;
        cursor: pointer;
        color: #717D9D;
        text-align: center;
        white-space: nowrap;
        // text-transform: capitalize;
    }

    .item_active{
        color: #FCFCFD;
    }

    .line{
        position: absolute;
        bottom: -1px;
        width: 10%;
        height: 2px;
        background: #EC7C40;
        border-radius: 3px;
        transition: .3s;
    }
}