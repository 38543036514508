.modules {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.module {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
  background: #171719;
  cursor: pointer;
}

.module_header {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;

  .main_info {
    display: flex;
    gap: 10px;

    .data {
      display: flex;
      gap: 5px;
      align-items: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 31.2px */
      letter-spacing: 0.24px;

      p {
        color: #E8E9E9;
      }

      span {
        color: #C35C25;
      }
    }
  }
}

.module_values {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.module_values_item {
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
}

.item {
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  border-radius: 8px;
  background: #1E1E21;

  &_name {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;

    .main_info {
      display: flex;
      gap: 10px;

      .data {
        display: flex;
        gap: 5px;
        align-items: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 31.2px */
        letter-spacing: 0.24px;

        p {
          color: #CCCCCC;
        }

        span {
          color: #B64E17;
        }
      }
    }

  }
}

.menu {
  display: flex;
  width: max-content;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 4px 9.9px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  margin-left: auto;

  top: 48px;
  right: 20px;

  &_item {
    display: flex;
    gap: 15px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 5px 5px 0px 0px;
    border-top: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
    border-right: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
    border-left: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
    background: #27282E;
    color: var(--Main-pallet-Grayscale-10, #EEEFF1);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    z-index: 11;

    &:hover {
      background: var(--Main-pallet-Grayscale-80, #232428);
      cursor: pointer;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.setting {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-left: auto;

  .setting_arrow {
    transform: rotate(180deg);
  }
  :hover {
    cursor: pointer;
  }
}

.hide {
  height: 74px;
  overflow: hidden;
  width: 100%;

  .setting_arrow {
    transform: rotate(0);
  }
}

.hide_1 {
  height: 54px;
  overflow: hidden;
  width: 100%;

  .setting_arrow {
    transform: rotate(0);
  }
}

.container, .container_first, .container_second, .container_third {
  width: 100%;
  position: relative;
}

.deletedElement {
  color: var(--Main-pallet-Accent-100, #EC7C40);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.16px;
}

.type {
  border-radius: 4px;
  border: 1px solid var(--Main-pallet-Grayscale-60, #3D3D3D);
  color: var(--Main-pallet-Grayscale-60, #3D3D3D);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;


  &_array {
    border-radius: 4px;
    /*
    border-image: linear-gradient(to right, #910E3866 0, #FFFFFF66 40%) 1;
    */

    background: rgba(61, 61, 61, 0.20);
    backdrop-filter: blur(6.5px);
    color: #910E38;
    border: 0.65px solid #910E3866;
  }

  &_object {
    backdrop-filter: blur(6.5px);
    border-radius: 4px;
    background: rgba(61, 61, 61, 0.20);
    color: #726DB1;
    border: 0.65px solid #726DB1;
  }
}

.wrap_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-left: auto;
  max-width: max-content;

  button {
    width: auto;
  }
}


