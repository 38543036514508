.menu {
  position: absolute;
  top: 25px;
  right: 0;

  display: flex;
  width: auto;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 4px 9.9px 0px rgba(0, 0, 0, 0.25);
  background: var(--Main-pallet-Grayscale-80, #232428);
  border: 1px solid var(--Main-pallet-Grayscale-70, #2D2D2D);
  border-radius: 5px;
  z-index: 11;

  &.section {
    top: 40px;
    right: 22px;
  }

  &.page {
    top: unset;
    bottom: -220%;
  }

  .item {
    display: flex;
    padding: 10px;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: var(--Main-pallet-Grayscale-80, #232428);

    &:hover {
      cursor: pointer;
      background: #27282E;
      transition: all 0.3s ease;
    }

    img {
      width: 20px;
      height: 20px;
    }

    span {
      color: var(--Main-pallet-Grayscale-10, #EEEFF1);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
    }

    &:nth-child(2) {
      span {
        color: var(--Status-red, #D84848);
      }
    }

  }
}