.manager_wrapper{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .content{
        display: flex;
        background: #232428;
        flex: 1 0 auto;

        .sidebar{
            border-right: 1px #363636 solid;
            width: 20%;
            min-width: 280px;
            position: relative;
        }

        .board{
            width: 80%;
            position: relative;

            .header{
                display: flex;
                align-items: center;
                .left_bar{
                    display: flex;
                    align-items: center;

                    .item{
                        width: fit-content;
                    }
                }
                .close{
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                }
            }

            .board_content{
                padding: 20px;
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                row-gap: 40px;
                padding-top: 50px;
            }
        }

        .header{
            display: flex;
            background: #1E1E21;
    
            .left_bar{
                display: flex;
                width: 100%;
    
                .item{
                    padding: 10px 15px;
                    cursor: pointer;
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    transition: .2s;
                    width: 50%;
    
                    .title{
                        font-weight: 500;
                        font-size: 16px;
                        user-select: none;
                        white-space: nowrap;
                    }
    
                    svg{
                        width: 25px;
                        height: 25px;
                    }
    
                    &:hover{
                        background: #232428;
                    }
                }
    
                .upload_wrapper{
                    position: relative;
    
                    input{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0; 
                        left: 0;
                        opacity: 0;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .loading{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(2px);
        display: flex;
        justify-content: center;
        align-items: center;

        svg{
            animation: rotation 1s infinite linear;
        }
    }
}


@keyframes rotation{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}